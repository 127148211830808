import React from 'react'
import { Col, InputGroup } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import monthSelect from 'flatpickr/dist/plugins/monthSelect'
import DatePicker from '../../../../common/DatePicker'

const DateFilterByMonth = ({ onChange, inputs }) => {
  return (
    <React.Fragment>
      <Col lg={2}>
        <label className='form-check-label mt-3 ' htmlFor='check1'>
          Date:
        </label>
      </Col>
      <Col lg={8}>
        <div className=''>
          <InputGroup>
            <DatePicker
              selected={inputs.startDate ? inputs.startDate : null}
              name='startDate'
              onChange={onChange}
              placeholderText={`Select Month`}
              className='form-control'
              dateFormat='MMM/yyyy'
              showMonthYearPicker
            />
            <i className='fa fa-calendar cal-icon'></i>
          </InputGroup>
          
        </div>
      </Col>
    </React.Fragment>
  )
}

export default DateFilterByMonth
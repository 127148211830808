import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function removeBodyCss() {
  document.body.classList.add('no_padding');
}

const handleMap = (val) => {
  window.open(val);
};
export const AdditionalInformation = (props) => {
  // const [modal_center, setmodal_center] = useState(false);

  // function contact_center_modal() {
  //     setmodal_center(!modal_center);
  //     removeBodyCss();
  //   }
  let additionalInfo = props.additionalInfo;
  let url = '';
  if (additionalInfo.address_2) {
    url = `${additionalInfo.address_1},${additionalInfo.address_2},${additionalInfo.city},${additionalInfo.state_name},${additionalInfo.country_name}`;
  } else {
    url = `${additionalInfo.address_1},${additionalInfo.city},${additionalInfo.state_name},${additionalInfo.country_name}`;
  }

  return (
    <div class="row text-left">
      <div class="col-lg-12 col-xl-12 mb-4 box-head">
        <strong>Additional Information :</strong>
      </div>
      <div
        class="col-lg-3 col-xl-3 mb-4 box-head add-left"
        style={{ textTransform: 'capitalize' }}
      >
        {' '}
        <i class="fa fa-user-circle add-icon"></i>{' '}
        {additionalInfo.secretary?.first_name}{' '}
        {additionalInfo.secretary?.last_name}
      </div>
      <div className="col-lg-3 col-xl-3 mb-4 box-head add-left">
        <i className="fa fa-envelope add-icon"></i>
        <span
          onClick={() => {
            props.contact_center_modal();
          }}
          data-toggle="modal"
          data-target=".bs-example-modal-center"
          id="display_popup"
        >
          Click here to contact
        </span>
      </div>
      <div class="col-lg-3 col-xl-3 mb-4 box-head add-left">
        {' '}
        <i class="fa fa-phone-alt add-icon"></i>{' '}
        <a href={`tel:${additionalInfo.secretary?.phone}`}>
          {' '}
          {/* {additionalInfo.secretary?.phone} */}
          {`${additionalInfo.secretary?.phone.substring(
            0,
            3
          )}-${additionalInfo.secretary?.phone.substring(
            3,
            6
          )}-${additionalInfo.secretary?.phone.substring(
            6,
            additionalInfo.secretary?.phone.length
          )}`}
        </a>{' '}
      </div>
      <div
        class="col-lg-3 col-xl-3 box-head mb-4 text-center1 add-left"
        style={{ textTransform: 'capitalize' }}
      >
        {' '}
        <i class="fa fa-trophy add-icon"></i> {additionalInfo.event_type}{' '}
      </div>
      <div class="col-lg-12 col-xl-12 box-head mb-4 add-left">
        {' '}
        <i class="fa fa-address-card add-icon"></i> {additionalInfo.address_1}{' '}
        {additionalInfo.address_1 ? ', ' : ''}
        {additionalInfo.address_2}
        {additionalInfo.address_2 ? ', ' : ''}
        {additionalInfo.city}
        {additionalInfo.city ? ', ' : ''}
        {additionalInfo.state_name}
        {additionalInfo.state_name ? ', ' : ''}
        {additionalInfo?.zip_code}
        {additionalInfo?.zip_code ? ', ' : ''}
        {additionalInfo?.country_name}
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleMap(`https://www.google.com/maps/search/${url}`);
          }}
        >
          <i class="fa fa-map-marker-alt map-icon" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  );
};